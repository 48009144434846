.dashboard-page{
    padding: 20px;
    width: calc(100vw - 350px);
}

.page-title{
   color: rgb(33, 43, 54);
   font-weight: 700;
   font-size: 20px;
}

.pagecard-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}
.page-card{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 20px;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card-title{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
}


.page-bar-section{
    /* height: 100px; */
    margin: 20px;
    margin-bottom: 80px;
}


.graph-sec-1{
    height: 350px;
    display: flex;
}

.graph-sec-11{
    width: 65%;
    height: 400px ;

    margin-right: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 10px; */
}

.graph-sec-12{
    width: 35%;
    height: 400px ;

    margin-left: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 10px; */
}

.graph-sec-21{
    width: 55%;
    height: 400px ;
    margin-right: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 10px; */
}

.graph-sec-22{
    width: 45%;
    height: 400px ;

    margin-left: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 10px; */
}


.graph-sec-31{
    width: 65%;
    height: 370px ;

    margin-right: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 10px; */
}

.graph-sec-32{
    width: 35%;
    height: 370px ;
    margin-left: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* padding: 10px; */
}


.graph-title{
    font-weight: 500 !important;
    margin-left: 30px;
    font-size: 18px;
    margin-top: 25px;
}

.graph-sub-title{
    margin-bottom: 20px;
    font-weight: 300;
    margin-left: 30px;
    font-size: 14px;
    color: rgb(91, 91, 91);
}

.graph-sec-3{
    /* height: 350px; */
    padding-top: 30px;


    display: flex;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

/* .graph-sec-1{
    width: 100%;
    height: 400px;
    color: rgb(241, 137, 0);
} */

.Proces_wise_cubes{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    justify-content: space-evenly;
    /* overflow-y: scroll; */
}

.FiltersCont{
    /* width: 100%; */
    /* height: 370px ; */
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px;
    /* padding: 10px; */
}

.filteroptionscont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filteroptions{
    width: 85%;
}

.addindibtn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}